import { template as template_e83526617fcc462dac92cc74136b17fa } from "@ember/template-compiler";
import SortableColumn from "./sortable-column";
const ActivityCell = template_e83526617fcc462dac92cc74136b17fa(`
  <SortableColumn
    @sortable={{@sortable}}
    @number="true"
    @order="activity"
    @activeOrder={{@activeOrder}}
    @changeSort={{@changeSort}}
    @ascending={{@ascending}}
    @name="activity"
  />
`, {
    eval () {
        return eval(arguments[0]);
    }
});
export default ActivityCell;
