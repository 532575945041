import { template as template_4d5dab7bd2244f1f9d157c7839b16881 } from "@ember/template-compiler";
const FKText = template_4d5dab7bd2244f1f9d157c7839b16881(`
  <p class="form-kit-text" ...attributes>
    {{yield}}
  </p>
`, {
    eval () {
        return eval(arguments[0]);
    }
});
export default FKText;
