import { template as template_187c3cc773b84f708fb46f7785f0a237 } from "@ember/template-compiler";
import { hash } from "@ember/helper";
const DropdownItem = template_187c3cc773b84f708fb46f7785f0a237(`
  <li class="dropdown-menu__item" ...attributes>{{yield}}</li>
`, {
    eval () {
        return eval(arguments[0]);
    }
});
const DropdownDivider = template_187c3cc773b84f708fb46f7785f0a237(`
  <li ...attributes><hr class="dropdown-menu__divider" /></li>
`, {
    eval () {
        return eval(arguments[0]);
    }
});
const DropdownMenu = template_187c3cc773b84f708fb46f7785f0a237(`
  <ul class="dropdown-menu" ...attributes>
    {{yield (hash item=DropdownItem divider=DropdownDivider)}}
  </ul>
`, {
    eval () {
        return eval(arguments[0]);
    }
});
export default DropdownMenu;
